import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Rating from '@mui/material/Rating'
import Pagination from '@mui/material/Pagination'
import useGet from 'hooks/useGet'
import moment from 'moment'
import ModalComponent from 'components/common/Modal'
import UserImage from 'assets/images/user-image.jpeg'
import {
  Container,
  HeadingWrapper,
  Heading,
  Wrapper,
  ReviewsListSection,
  ReviewCard,
  UserAndRatingWrapper,
  UserWrapper,
  UserImageWrapper,
  UserDetailWrapper,
  UserName,
  ReviewDate,
  RatingWrapper,
  ReviewImageWrapper,
  ReviewDataWrapper,
  ReviewData,
  PaginationWrapper,
} from 'styles/hpLayout/reviews'

const Reviews = () => {
  const { id } = useParams()
  const [pagination, setPagination] = useState({
    currentPage: 1,
    postPerPage: 10,
  })
  const [totalReviewsCount, setTotalReviewsCount] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data: allReviewsData, refetch: fetchAllReviews } = useGet(
    'getAllReviews',
    `/clientApis/v2/reviews/${id}?page=${pagination.currentPage}&limit=${pagination.postPerPage}`,
  )

  useEffect(() => {
    fetchAllReviews()
  }, [pagination.currentPage, id])

  useEffect(() => {
    if (allReviewsData) {
      setTotalReviewsCount(allReviewsData?.pagination?.totalReviews || 0)
    }
  }, [allReviewsData])

  const reviewsListData = allReviewsData?.data || []

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedImage(null)
  }

  return (
    <Container>
      <HeadingWrapper>
        <Heading>Reviews</Heading>
      </HeadingWrapper>
      <Wrapper>
        <ReviewsListSection>
          {reviewsListData?.map((item, index) => (
            <ReviewCard key={index}>
              <UserAndRatingWrapper>
                <UserWrapper>
                  <UserImageWrapper>
                    <img
                      src={item?.userDetails?.userImage ? item?.userDetails?.userImage : UserImage}
                      alt={item?.userDetails?.userName}
                      onError={(e) => {
                        e.target.src =
                          'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }}
                    />
                  </UserImageWrapper>
                  <UserDetailWrapper>
                    <UserName>{item?.userDetails?.userName}</UserName>
                    <ReviewDate>Commented on - {moment(item?.updatedAt).format('LL')}</ReviewDate>
                  </UserDetailWrapper>
                </UserWrapper>
                <RatingWrapper>
                  <Rating name={`rating-${index}`} value={item?.rating || 0} precision={0.5} readOnly />
                </RatingWrapper>
              </UserAndRatingWrapper>
              <ReviewImageWrapper>
                {item?.imageUrl && (
                  <img
                    src={item?.imageUrl}
                    alt="review-image"
                    onError={(e) => {
                      e.target.src =
                        'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                    }}
                    onClick={() => handleImageClick(item?.imageUrl)}
                  />
                )}
              </ReviewImageWrapper>
              <ReviewDataWrapper>{item?.review && <ReviewData>{item?.review}</ReviewData>}</ReviewDataWrapper>
            </ReviewCard>
          ))}
        </ReviewsListSection>
        <PaginationWrapper>
          {reviewsListData?.length > 0 && totalReviewsCount > pagination.postPerPage && (
            <PaginationWrapper>
              <Pagination
                count={Math.ceil(totalReviewsCount / pagination.postPerPage)}
                shape="rounded"
                color="primary"
                page={pagination.currentPage}
                onChange={(event, currentPage) => {
                  event
                  setPagination((prevState) => ({ ...prevState, currentPage }))
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
              />
            </PaginationWrapper>
          )}
        </PaginationWrapper>
      </Wrapper>
      <ModalComponent open={isModalOpen} onClose={closeModal} title="Image Preview">
        {selectedImage && <img src={selectedImage} alt="Selected Review" style={{ width: '100%' }} />}
      </ModalComponent>
    </Container>
  )
}

export default Reviews
