import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '@mui/material/TextField'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import MenuItem from '@mui/material/MenuItem'
import { CONTACT_US_VALIDATION } from 'utils/Validation/contactForm-validation'
import EmailIcon from 'assets/svg/EmailIcon'
import PhoneIcon from 'assets/svg/PhoneIcon'
import usePost from 'hooks/usePost'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { ToastContext } from 'context/toastContext'

import {
  ContactFormWrapper,
  ContactDetailSection,
  HeadingWrapper,
  MainHeading,
  SubHeading,
  FormWrapper,
  Wrapper,
  FieldWrapper,
  FieldTitle,
  FieldInputWrapper,
  ErrorMessage,
  ButtonWrapper,
  DetailSection,
  ContactDetailWrapper,
  DetailWrapper,
  ContactDetail,
  ContactContent,
} from 'styles/FooterLinks/ContactUsPage'
import CustomButton from 'components/customButton'

const ContactForm = () => {
  const { mutateAsync } = usePost()
  const dispatch = useContext(ToastContext)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CONTACT_US_VALIDATION),
  })
  const submitData = async (data) => {
    try {
      const res = await mutateAsync({
        url: '/clientApis/v2/contact',
        payload: {
          username: data?.name,
          email: data?.email,
          subject: data?.subject,
          message: data?.message,
        },
      })
      if (res) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: res?.message,
          },
        })
      }

      reset()
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.message,
        },
      })
    }
  }

  return (
    <ContactFormWrapper>
      <ContactDetailSection>
        <HeadingWrapper>
          <MainHeading>We’d love to hear from you!</MainHeading>
          <SubHeading>Let’s get in touch</SubHeading>
        </HeadingWrapper>
        <DetailSection>
          <ContactDetailWrapper>
            <DetailWrapper>
              <EmailIcon />
              <ContactDetail>
                <ContactContent>Mail Us</ContactContent>
                <ContactContent>himira.hp@gmail.com</ContactContent>
              </ContactDetail>
            </DetailWrapper>
          </ContactDetailWrapper>
          <ContactDetailWrapper>
            <DetailWrapper>
              <PhoneIcon />
              <ContactDetail>
                <ContactContent>Call Us</ContactContent>
                <ContactContent>+91-9816012233</ContactContent>
              </ContactDetail>
            </DetailWrapper>
          </ContactDetailWrapper>
        </DetailSection>
      </ContactDetailSection>
      <FormWrapper onSubmit={handleSubmit(submitData)}>
        <Wrapper>
          <FieldWrapper>
            <FieldTitle>
              Name<span className="asterik">*</span>
            </FieldTitle>
            <FieldInputWrapper>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="name"
                    placeholder="Enter Name"
                    style={{ width: '100%' }}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                    }}
                  />
                )}
              />
              <ErrorMessage>{errors?.name?.message}</ErrorMessage>
            </FieldInputWrapper>
          </FieldWrapper>
          <FieldWrapper>
            <FieldTitle>
              Email<span className="asterik">*</span>
            </FieldTitle>
            <FieldInputWrapper>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="email"
                    placeholder="Enter Email"
                    style={{ width: '100%' }}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                    }}
                  />
                )}
              />
              <ErrorMessage>{errors?.email?.message}</ErrorMessage>
            </FieldInputWrapper>
          </FieldWrapper>
        </Wrapper>
        <FieldWrapper>
          <FieldTitle>
            Subject<span className="asterik">*</span>
          </FieldTitle>
          <FieldInputWrapper>
            <Controller
              name="subject"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  select
                  id="subject"
                  placeholder="Select Subject"
                  style={{ width: '100%', background: '#fff' }}
                  SelectProps={{
                    displayEmpty: true,
                    sx: {
                      height: '40px',
                      color: '#61606070',
                      fontSize: '14px',
                    },
                    renderValue: (selected) => {
                      return selected ? selected : 'Select Subject'
                    },
                  }}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                >
                  <MenuItem value="Order Issues">Order Issues</MenuItem>
                  <MenuItem value="Product Feedback">Product Feedback</MenuItem>
                  <MenuItem value="Delivery Experience">Delivery Experience</MenuItem>
                  <MenuItem value="Customer Service">Customer Service</MenuItem>
                  <MenuItem value="Refund/Return Issues">Refund/Return Issues</MenuItem>
                  <MenuItem value="Payment Issues">Payment Issues</MenuItem>
                  <MenuItem value="Website/App Experience">Website/App Experience</MenuItem>
                  <MenuItem value="Promotions/Discounts">Promotions/Discounts</MenuItem>
                  <MenuItem value="Suggestions">Suggestions</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              )}
            />
            <ErrorMessage>{errors?.subject?.message}</ErrorMessage>
          </FieldInputWrapper>
        </FieldWrapper>

        <FieldWrapper>
          <FieldTitle>
            Message<span className="asterik">*</span>
          </FieldTitle>
          <FieldInputWrapper>
            <Controller
              name="message"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextareaAutosize
                  id="message"
                  placeholder="Enter Message"
                  style={{ width: '100%' }}
                  minRows={8}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                />
              )}
            />
            <ErrorMessage>{errors?.message?.message}</ErrorMessage>
          </FieldInputWrapper>
        </FieldWrapper>
        <ButtonWrapper>
          <CustomButton label="Submit" variant="contained1" />
        </ButtonWrapper>
      </FormWrapper>
    </ContactFormWrapper>
  )
}

export default ContactForm
