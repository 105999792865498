import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  margin: 150px 100px 0 100px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  @media (max-width: 450px) {
    margin: 16px;
    margin-top: 80px;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Heading = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 29.26px;
    text-align: center;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`
export const ReviewsListSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-bottom: 1px solid #00000026;
`
export const UserAndRatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const UserWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const UserImageWrapper = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain;
  }
`
export const UserDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const UserName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17.07px;
  color: ${theme.HPTEXTCOLORPRIMARYLIGHT};
`
export const ReviewDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  color: ${theme.HPTEXTCOLORSECONDARYLIGHT};
`
export const RatingWrapper = styled.div``
export const ReviewImageWrapper = styled.div`
  img {
    width: 75px;
    height: 75px;
    border-radius: 10px;
    overflow: hidden;
    object-fit: cover;
    cursor: pointer;
  }
`
export const ReviewDataWrapper = styled.div`
  display: flex;
  width: 100%;
`
export const ReviewData = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: ${theme.HPTEXTCOLORPRIMARYLIGHT};
`
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
