export const theme = {
  PRIMARYCOLOR: '#EBB935',
  // PRIMARYCOLOR: '#115469',

  CARDCOLOR: '#00000033',
  CARDCOLORLIGHT: '#00000030',

  BORDERCOLOR: '#bebcbd',
  BORDERCOLORLIGHT: '#e4e4e4',
  PRIMARYBORDERCOLOR: '#EBB935',
  // PRIMARYBORDERCOLOR: '#11546980',
  SECONDARYBORDERCOLOR: '#e8e8e8',
  TERTIARYBORDERCOLOR: '#d9d9d9',
  BORDERCOLORDARK: '#b5cad1',
  MODALBORDERCOLOR: '#bce5fc',
  PRIMARYBORDERCOLORDARK: '#92b0ba',
  PRIMARYBORDERCOLORLIGHT: '#eaf6ff',
  TEXTCOLOR: '#00000099',
  TEXTCOLORLIGHT: '#8f8f8f',
  PRIMARYTEXTCOLOR: '#000',
  PRIMARYTEXTCOLORDARK: '#1d1d1d',
  SECONDARYTEXTCOLOR: '#fff',
  TERTIARYTEXTCOLOR: '#363636',
  SECONDARYTEXTCOLORDARK: '#5f6c72',
  TERTIARYCOLORDARK: '#3c4242',
  GREYCOLOR: '#979797',
  GREYCOLORDARK: '#b9b9b9',
  PRIMARYGREYCOLOR: '#686868',
  SECONDARYGREYCOLOR: '#807d7e',
  TERTIARYGREYCOLOR: '#e1e1e1',
  DESCRIPTIONTEXTCOLOR: '#0c3c4b',

  BACKGROUNDCOLORLIGHT: '#a5c2cbba',
  PRIMARYBACKGROUNDCOLOR: '#00000014',
  SECONDARYBACKGROUNDCOLOR: '#e7eef0',
  TERTIARYBACKGROUNDCOLOR: '#f6f6f6',
  BACKGROUNDCOLOR: '#e8fcf1',
  PAYMENTBACKGROUNDCOLOR: ' #f2f2f2',
  LOADERBACKGROUNDCOLOR: '#0000005c',
  CONTACTBACKGROUNDCOLOR: '#417687',

  DARKBLUE: '#1c75bc',
  LIGHTBLUE: '#acd4f1',
  LIGHTGREEN: '#e6fee6',
  SECONDARYYELLOW: '#f9c61c33',
  TRANSPARENTBLUE: '#f5f9fe',
  PRIMARYGREEN: '#08d604',
  PRIMARYDARKYELLOW: '#e2b00e',
  TERTIARYBLUE: '#107ee3',
  LIGHTPURPLE: '#c9d1fa',
  PURPLE: '#1d2e88',

  ERROR: '#e43030',
  SECONDARYERRORCOLOR: '#d83232',
  TERTIARYERRORCOLOR: '#d32f2f',
  ERRORCOLORLIGHT: '#fee6e6',

  SUCCESS: '#419e6a',
  SECONDARYSUCCESSCOLOR: '#41b06e',

  INPUTCOLOR: '#979797',
  LABELCOLOR: '#3b3b3b',

  DISABLECOLOR: '#b0b0b0,',

  DIVIDERCOLOR: '#cacdd8',
  PRIMARYDIVIDERCOLOR: '#e0e0e0',
  CUSTOMIZEDCOLOR: '#a2a6b0',
  CUSTOMIZATIONLABELCOLOR: '#eb9494',

  DISCOUNTCOLOR: '#b1e3b1',

  TIMELINEHEADERCOLOR: '#8a8a8a',
  TIMELINECONNECTOR: '#0000001a',

  CROSSICONCOLOR: '#f0f0f0',

  //Hp
  HPBACKGROUNDCOLOR: '#FFFFF9',

  HPPRIMARYCOLOR: '#EBB935',
  HPPRIMARYCOLORDARK: '#D09427',
  HPSECONDARYCOLOR: '#734D00',
  HPPRIMARYLIGHTCOLOR: '#F0F1F1',
  HPTEXTCOLORPRIMARY: '#282828',
  HPTEXTCOLORSECONDARYCOLORY: '#FFFFFF',
  HPTEXTCOLORTERTIONARY: '#979797',
  HPTEXTCOLORDARK: ' #000000',
  HPPRIMARYTEXTCOLORDARK: '#1A1A1A',
  HPTEXTCOLORLIGHT: '#4E4D4D',
  HPTEXTCOLORPRIMARYLIGHT: '#363535',
  HPTEXTCOLORSECONDARYLIGHT: '#888888',
  HPSECONDARYLIGHTTEXTCOLOR: '#7A7A7A',
  HPPRIMARYBORDERCOLOR: '#E8E8E8',
  HPBACKGROUNDCOLORPRIMARY: '#F5F5F5',
  HPFOOTERTEXTCOLOR: '#F6F6F6',
  HPBORDERCOLOR: '#aa8041',
  HPCARDBORDERCOLOR: '#E4E7E9',
  HPGRADIANTCOLOR: 'linear-gradient(90deg, #F3C538 0%, #BE7C1F 100%)',
}
