import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useGet from 'hooks/useGet'
import Rating from '@mui/material/Rating'
import moment from 'moment'
import ModalComponent from 'components/common/Modal'
import UserImage from 'assets/images/user-image.jpeg'

import {
  ReviewsSection,
  HeadingWrapper,
  ProductDetailHeading,
  ViewAllText,
  BoxWrapper,
  ReviewWrapper,
  UserDetailWrapper,
  UserImageWrapper,
  UserWrapper,
  UserName,
  ReviewDetailContainer,
  RatingWrapper,
  ReviewImageWrapper,
  Review,
  ReviewDate,
} from 'components/application/product-list/product-details/style'

const ProductReviews = (itemId) => {
  const history = useHistory()
  const [selectedImage, setSelectedImage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data: reviewsData, refetch: fetchReviews } = useGet(
    'getReviews',
    `/clientApis/v2/reviews/${itemId?.itemId}?page=1&limit=10`,
  )
  const reviews = reviewsData?.data

  useEffect(() => {
    fetchReviews()
  }, [itemId])

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedImage(null)
  }

  return (
    <>
      {reviews?.length > 0 && (
        <ReviewsSection>
          <HeadingWrapper>
            <ProductDetailHeading>Reviews</ProductDetailHeading>
            {reviews?.length > 4 && (
              <ViewAllText
                onClick={() => {
                  history.push(`/reviews/${itemId?.itemId}`)
                }}
                itemId={itemId?.itemId}
              >
                All Reviews
              </ViewAllText>
            )}
          </HeadingWrapper>
          <BoxWrapper>
            {reviews?.slice(0, 4).map((item, index) => (
              <ReviewWrapper key={index}>
                <ReviewDetailContainer>
                  <RatingWrapper>
                    <Rating name={`rating-${index}`} value={item?.rating || 0} precision={0.5} readOnly />
                  </RatingWrapper>
                  <ReviewImageWrapper>
                    {item?.imageUrl && (
                      <img
                        src={item?.imageUrl}
                        alt="review-image"
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                        onClick={() => handleImageClick(item?.imageUrl)}
                      />
                    )}
                  </ReviewImageWrapper>
                  {item?.review && <Review>{item?.review}</Review>}
                </ReviewDetailContainer>
                <UserDetailWrapper>
                  <UserImageWrapper>
                    <img
                      src={item?.userDetails?.userImage ? item?.userDetails?.userImage : UserImage}
                      alt={item?.userDetails?.userName}
                      onError={(e) => {
                        e.target.src =
                          'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }}
                    />
                  </UserImageWrapper>
                  <UserWrapper>
                    <UserName>{item?.userDetails?.userName}</UserName>
                    <ReviewDate>Commented on - {moment(item?.updatedAt).format('LL')}</ReviewDate>
                  </UserWrapper>
                </UserDetailWrapper>
              </ReviewWrapper>
            ))}
          </BoxWrapper>
        </ReviewsSection>
      )}
      <ModalComponent open={isModalOpen} onClose={closeModal} title="Image Preview">
        {selectedImage && <img src={selectedImage} alt="Selected Review" style={{ width: '100%' }} />}
      </ModalComponent>
    </>
  )
}

export default ProductReviews
